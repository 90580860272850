import React from "react"
import classnames from "classnames"
import { components } from "react-select"

import PaintSummary from "../../paint_summary"

const PaintOption = ({ data, ...props }) => {
  const className = classnames("alternative-paint-selector__option", {
    "alternative-paint-selector__option--selected": props.isSelected
  })

  return (
    <components.Option className={className} {...props}>
      <PaintSummary {...data} />
    </components.Option>
  )
}

export default PaintOption
